import * as React from 'react'
import Logo from '../../images/oldLogo.svg'
import { Link } from 'gatsby'
const terms = () => {
  return <div className='px-7.5 py-7.5 overflow-hidden text-gotin bg-gotin text-center'>
    <div className='flex justify-center'>
      <img src={Logo} alt="gotin" className='w-45' />
    </div>
    <h2 className="text-center my-5 text-xlg font-semibold">Service Agreement</h2>
    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">Please read carefully and fully understand the following terms and conditions, especially the terms of exemption or limitation of liability, dispute resolution and applicable laws. The terms of exemption or limitation of liability will be displayed in bold, and you should read them emphatically.</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">This "User Service Agreement" (hereinafter referred to as the "Agreement") applies to your access and use of the official website of Beijing Yoda Technology Co., Ltd. <span className='text-gotinc'><Link to="/" >https://www.gotin.online</Link></span> and various online services including event website, APP, WeChat public account, etc. undertaken by Yoda. This agreement is a legally binding agreement between you (hereinafter referred to as "You" or "User") and [Beijing Yoda Technology Co., Ltd.] (hereinafter referred to as "We" or "Yoda". The aforementioned "user" "Includes exhibitor users and platform users.</p>

    <h4 className="font-semibold my-2.5">1. Application and acceptance of this agreement</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">1.1 You visit primary official websites, APPs, WeChat official accounts, etc. (hereinafter referred to as "Related Websites") that have major official websites and various online activities hosted by Yoda and use various tools and content provided by the aforementioned websites and platforms, including but not limited to visits and logins to relevant websites, browsing and use of the above-mentioned content, uploading, displaying exhibits, decorating online booths through the above-mentioned websites and platforms, live streaming, online marketing, registering accounts, inquiring about exhibits, conducting inquiries, etc. on the aforementioned websites and platforms, indicate that you have fully read, understood, and voluntarily accepted all the contents of this agreement and are bound by it. If you do not accept the content of this agreement, you should immediately stop accessing the above-mentioned websites and platforms and/or using major services.<br/>

    1.2 The content of this agreement includes the main body of this agreement and the privacy policy, Cookies policy, exhibit upload policy, disclaimer, copyright statement and any other rules, policies, statements, notices, warnings, tips, instructions (hereinafter referred to as "Yoda rules"). The foregoing rules are an integral part of this agreement and have the same legal effect as this agreement.<br/>

    1.3 If according to the laws of the People’s Republic of China and/or other applicable laws (including the laws of the country/region where you live): (1) You do not have the full capacity for civil rights and the capacity for conduct appropriate to the civil behavior you are engaged in, and you have not visited Yoda related websites and the use of major services have been approved by your legal guardian, or (2) you are prohibited from accessing Yoda websites and/or use major services, you must not visit Yoda websites and/or use major services. Otherwise, you and/or your guardian shall bear all the consequences arising therefrom, and we have the right to cancel your account and claim against you and/or your guardian. If you use Yoda service on behalf of a company, organization, or other legal entity, you declare and warrant that you have been fully authorized and have the right to use Yoda service on behalf of the company, organization, or legal entity, and are bound by this agreement.<br/>

    1.4 We may revise or update all or part of this agreement at any time, and any revision or update will take effect when it is released. The changed agreement will be announced on Yoda websites, so you should browse this agreement regularly to check the changes. If you continue to visit Yoda related websites or use Yoda services after this agreement is changed, it means that you agree to accept the revised or updated agreement. If you do not agree to the relevant changes, you should immediately stop visiting the related websites or using the related services.<br/>

    1.5 If we publish or provide the English version of this agreement, you agree that such English version is only for your convenience. If the English version is inconsistent with the Chinese version, the Chinese version shall prevail.<br/>

    1.6 You may need to sign a separate agreement (collectively referred to as "additional agreement") with us or our affiliates for any service (or function in the service) (whether online or offline). If there is any conflict or inconsistency between this agreement and the additional agreement, the additional agreement shall prevail over this agreement only in terms of the service (or function in the service).<br/>
    </p>

    <h4 className="font-semibold my-2.5">2. Accounts and services</h4>
    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
    2.1 Exhibitors<br/>

    2.1.1 Exhibitors can log in to the relevant website and use the service of Yoda through the registered account on the platform of Yoda and/or related parties.<br/>

    2.1.2 The first time you log in to a Yoda website through an exhibitor's account, you need to verify it through the reserved or filled-in email address. Exhibitor users can create accounts to upload and maintain display materials, adjust display content, and collect user business cards and other services.<br/>

    2.2 Platform users<br/>

    2.2.1 Platform users can log in to the relevant websites and use the services of Yoda through a registered account.<br/>

    2.3 After you apply for a registered account, we will review your registration information, and may reject your registration application for any reason, restrict or ban accounts that have not passed the review.<br/>

    2.4 When registering, using and managing your account, you guarantee that the materials you submit or the information you fill in are true, accurate and complete. In accordance with the provisions of applicable Chinese laws and regulations, in order to use some of the functions with great services, you need to fill in your true identity information. Please complete the real-name authentication in accordance with relevant laws and regulations, and pay attention to timely update the above-mentioned related information. If we discover or have reasonable grounds to suspect that the materials you submit or the information provided are untrue, inaccurate, incomplete or illegal, we have the right to reject your registration application or stop providing you with related services. You may not be able to use some functions of the service or are restricted.<br/>

    2.5 You should ensure that you are not the subject of trade restrictions, sanctions or other laws and regulations imposed by any country, international organization or region, otherwise you may not be able to register and use the big service normally.<br/>

    2.6 You only obtain the right to use the relevant account, and you may not transfer, rent, sell or authorize the account to any third party. If we discover or have reason to believe that the user is not the initial registrant of the account, in order to protect the security of the account, we have the right to immediately suspend or terminate the provision of services to the registered account, and have the right to permanently disable the account.<br/>

    2.7 You are responsible for maintaining the security and confidentiality of your account and password, and assume full legal responsibility for the activities you engage in in the name of the registered account. You should attach great importance to the confidentiality of your account and password, and do not disclose your account and password to others under any circumstances. If you find that someone else uses your account without permission or any other security breach occurs, you should immediately notify us and change your password. If there is reason to suspect that a third party knows your password, or there may be abuse, we have the right to block your account or take other necessary measures for security purposes before the situation is clarified. You understand and agree that we are not responsible for any loss or damage that is not attributable to us, caused by or related to any activity performed by any unauthorized person or data leakage of user account information.<br/>

    2.8 You understand and agree that Yoda services we provide are provided in accordance with the status quo that can be achieved with existing technology and conditions. We will do our best to provide you with services to ensure the continuity and safety of services. You understand that we cannot at any time or always foresee and prevent legal, technical and other risks, including but not limited to force majeure, network reasons, third-party service defects, third-party websites, etc., which may cause service interruption, and failure to normally visit related websites. Or use large services and other losses and risks.<br/>

    2.9 You understand and agree that, for the needs of overall service operation and platform operation security, we have the right to determine the setting and scope of the service/function according to the specific circumstances, modify, interrupt, suspend or terminate all or part of the functions or functions of Yoda related websites and service.<br/>
    </p>

    <h4 className="font-semibold my-2.5">3. Protection of user information</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
    3.1 For your personal information, including but not limited to your name, mobile phone number, e-mail address, etc., we will collect, use, store, share, transfer, publicly disclose and protect it in strict accordance with the "Privacy Policy" issued by us. Please read our "Privacy Policy" carefully.<br/>

    3.2 You understand and agree that we will collect other information other than your personal information, including but not limited to corporate information, live streaming data, display negotiation platform data, etc., through relevant websites or in the provision of major services; we may The three parties indirectly obtain the aforementioned relevant data; we will conduct statistical analysis on the directly or indirectly collected information to optimize the display of exhibits and live streaming effects, realize intelligent push, conduct follow-up research, or use it within a reasonable range of business; we will use it in accordance with relevant laws, regulations, legal procedures, mandatory requirements of relevant government agencies, and the need to provide services to you, publicly disclose such information or share such information with third parties.<br/>
    </p>

    <h4 className="font-semibold my-2.5">4. Third-party websites and applications</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
    4.1 Yoda related websites may contain links to external websites. Users should carefully read the terms of use and privacy agreements of these external websites. We are not responsible for the authenticity, legality or security of external websites (including data leakage, etc.), and we are not associated with such hyperlinks or websites, and do not represent such hyperlinks or websites.<br/>

    4.2 When you use applications, software or services provided by a third party while visiting a related website or using a service, you may need to agree to and abide by the agreement or rules of the third party in addition to complying with the related rules. If disputes, losses or damages arising from third-party applications, software and related services are resolved by you and the third party, we do not assume any responsibility to you or any third party.<br/>
    </p>

    <h4 className="font-semibold my-2.5">5. User Code of Conduct</h4>

    <h4 className="font-semibold my-2.5">5.1 General Code of Conduct</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">You agree to abide by all applicable laws and regulations when you visit Yoda websites and use Yoda services, and be responsible for your visits to Yoda websites and use of Yoda services. You must not engage in the following behaviors when visiting related websites and/or using major services:</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
    (1) Impersonate any person or entity, and distort the affiliation relationship between oneself and any person or entity;<br/>
    (2) Activities that endanger network security such as illegal intrusion into the network, interference with normal network functions, and theft of network data;
    (3) Provide programs and tools specially used to engage in network security activities such as intruding into the network, interfering with the normal network functions and protective measures, and stealing network data;<br/>
    (4) Provide technical support, advertising promotion, payment settlement and other assistance to others who knowingly others are engaged in activities that endanger network security;<br/>
    (5) Use any plug-in, plug-in system or third-party tool that is not authorized or permitted by us to control, interfere with, destroy, modify or exert other influences on the normal operation of related websites and services;<br/>
    (6) Use unauthorized data or access unauthorized servers/accounts;<br/>
    (7) Delete, modify or add data and applications stored, processed or transmitted in the computer information network without permission;<br/>
    (8) Deliberately making and spreading computer viruses and other destructive programs;<br/>
    (9) Violation of laws, regulations, policies, regulatory documents, this agreement, and other acts that infringe on the legitimate rights and interests of others.<br/>
    </p>

    <h4 className="font-semibold my-2.5">5.2 Rules for uploading exhibits</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">Exhibitor users uploading exhibits through Yoda websites shall comply with applicable laws and regulations and comply with the specifications of the "Exhibition Upload Manual" issued by us. Exhibitor users should read our "Exhibit Upload Manual" carefully.</p>

    <h4 className="font-semibold my-2.5">5.3 Information content display specification</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">The information you produce, copy, publish, and disseminate shall comply with the requirements of applicable laws and regulations. You agree and promise not to make, copy, publish or disseminate the following information:</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
    (1) Opposing the basic principles established by the Constitution of the People's Republic of China;<br/>
    (2) Endangering the national security of the People's Republic of China, leaking the state secrets of the People's Republic of China, subverting the state power of the People's Republic of China, and undermining the national unity of the People's Republic of China;<br/>
    (3) Damage to the national honor and interests of the People's Republic of China;<br/>
    (4) Inciting ethnic hatred and ethnic discrimination in the People's Republic of China, and undermining the ethnic unity of the People's Republic of China;<br/>
    (5) Undermine the national religious policy of the People's Republic of China and promote cults and feudal superstition;<br/>
    (6) Spreading rumors, disturbing social order, and undermining social stability;<br/>
    (7) Spreading obscenity, pornography, gambling, violence, murder, terror or instigating crime;<br/>
    (8) Insulting or slandering others, infringing on the lawful rights and interests of others;<br/>
    (9) Distorting, vilifying, blaspheming, denying the deeds and spirit of heroes and martyrs, insulting, slandering or otherwise infringing on the names, portraits, reputation, and honor of heroes and martyrs;<br/>
    (10) Propagating terrorism or extremism or inciting terrorist activities or extremist activities;<br/>
    (11) Damage to the credibility of the state organs of the People's Republic of China;<br/>
    (12) Any information about fake or inferior goods or services, including but not limited to goods that infringe on the intellectual property rights of others, goods that do not meet relevant national or industry standards, false services, and other information;<br/>
    (13) Other information that violates applicable laws, regulations, policies, public order and good customs, interferes with the normal operation of related websites, or violates the legitimate rights and interests of other users or third parties;<br/>
    (14) Contain any direct or indirect link to any other website, which contains any content that may violate this agreement;<br/>
    (15) Display or display other exhibitions, professional wholesale markets or other e-commerce websites that provide seller registration and third-party trade matching platform functions in any form on Yoda websites;<br/>
    (16) Conduct any form of publicity activities for other exhibitions, professional wholesale markets or e-commerce platforms on Yoda websites.<br/>
    </p>

    <h4 className="font-semibold my-2.5">5.4 Information content usage specification</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">Without our prior written permission, no user shall, on his or her own, authorize, allow, or assist others in the information content of Yoda websites and services (including but not limited to exhibitor information, exhibit information, purchaser information, statistical data) ) perform the following actions:</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
    (1) Copy, download, read, compile, and use information content with Yoda websites and services for commercial purposes including but not limited to publicity, increasing reading volume, and browsing volume;<br/>
    (2) Unauthorized editing, sorting, and arrangement of the information content of Yoda websites and services, and then displaying them on channels other than the source pages of Yoda websites and services;<br/>
    (3) Adopt any form of identification methods, including but not limited to special marks, special codes, etc., to self or assist a third party to generate traffic, read volume guidance, transfer, hijacking, etc., on Yoda websites and information content with major services Influence;<br/>
    (4) In any way (including but not limited to hotlinking, redundant stealing, illegal crawling, data crawling technology, simulated download, deep linking, fake registration, etc.) directly or indirectly stealing Yoda websites or Information content such as videos, graphics and texts of major services, or delete or change the rights management electronic information of related information content in any way (including but not limited to hiding or modifying domain names, platform-specific logos, user names, etc.);<br/>
    (5) Other acts of illegally acquiring or using information content with Yoda websites and major services.<br/>
    </p>

    <h4 className="font-semibold my-2.5">5.5 Advertising</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
    5.5.1 When users of exhibitors use Yoda websites to carry out advertising and promotion services, they should ensure that the advertising content is true and accurate, does not contain false or misleading content, and complies with the "Advertising Law of the People's Republic of China" and the "Anti-Unfair Competition Law" "And other applicable laws and regulations.<br/>

    5.5.2 The advertisement shall not have the following circumstances:<br/>
    </p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
    (1) Use or disguised use of the national flag, national anthem, national emblem, military flag, military anthem, and military emblem of the People's Republic of China;<br/>
    (2) Use or disguised use of the name or image of the state organs of the People's Republic of China and the staff of state organs;<br/>
    (3) Use terms such as "national", "superior", and "best";<br/>
    (4) Damage to the national dignity or interests of the People's Republic of China, and disclose the state secrets of the People's Republic of China;<br/>
    (5) Hinder social stability and harm the public interest of society;<br/>
    (6) Endanger personal and property safety and leak personal privacy;<br/>
    (7) Obstruct social public order or violate good social customs;<br/>
    (8) Content containing obscenity, pornography, gambling, superstition, terror, violence;<br/>
    (9) Contains content that discriminates against ethnicity, race, religion, or sex;<br/>
    (10) Obstruct the protection of the environment, natural resources or cultural heritage;<br/>
    (11) Other situations prohibited by laws and administrative regulations.<br/>
    </p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
    5.5.3 All kinds of advertisements published, as well as other advertisements that should be reviewed under applicable laws and administrative regulations, shall be reviewed by the advertising review agency before publishing; the advertising content shall not be published without review.<br/>

    5.5.4 Exhibitors and platform users understand and agree that we may push relevant exhibitors, exhibits or advertising information to you during your visits to related websites and the use of related services.<br/>

    5.5.5 For advertisements, push or promotion information, exhibitors and platform users shall judge the authenticity and reliability of the advertisement or promotion information by themselves and be responsible for their own judgments. Except as expressly provided by laws and regulations, you shall bear the responsibility for any damages or losses incurred due to the negotiation, transaction, or the foregoing content of the advertisement or promotion information, and we shall not be liable.<br/>
    </p>

    <h4 className="font-semibold my-2.5">6. Intellectual Property</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
    6.1 When you visit Yoda related websites and use Yoda services, you should carefully read and be bound by the relevant intellectual property regulations issued by us.<br/>
    6.2 We are the intellectual property owner or legal owner of Yoda websites and Yoda services (including but not limited to software, technology, programs, web pages, text, pictures, images, audio, video, charts, layouts, electronic documents, etc.) Licensee. Without our permission, no one is allowed to use (including but not limited to monitoring, copying, disseminating, displaying, mirroring, uploading, downloading) related websites and services through any program or equipment such as robots, "spiders", crawlers, etc. <br/>
    6.3 You are not allowed to use our activities without authorization under any circumstances, including but not limited to any trademarks, service marks, trade names, domain names, website names or other distinctive brand features (hereinafter collectively referred to as "logos"), including the partners of "Yoda Technology", "Yoda Technology", "Yoda Gotin", "<span className='text-gotinc'><Link to="/" >https://www.gotin.online</Link></span> " and various activities undertaken by Yoda. Without our prior written consent, you may not display, use or apply for trademark registration, domain name registration, etc. of the aforementioned logos in any single or combined manner, and you may not express or imply that you have the right to display, use, or method to deal with such signs. If you violate this agreement and use our above-mentioned trademarks, logos, etc. to cause losses to us or others, you shall bear all legal liabilities.<br/>
    6.4 You understand and promise that the content (including but not limited to text, pictures, video, audio and other forms of content and the music, sound, lines, visual design, dialogue, etc. contained in it) when you use Yoda websites and services that you upload, publish or transmit are originally created by you or have been legally authorized (including sub-authorization). The intellectual property rights of the content you upload and publish through Yoda websites belong to you or the original copyright owner.<br/>
    6.5 Unless we explicitly state otherwise, you know, understand and agree that the content (including but not limited to text, pictures, video, audio and other forms of content and the music, sound, lines, visual design, dialogue, etc. contained in it) you upload, publish or transmit through Yoda websites, you grant us and our affiliates a global-scope, free, non-exclusive, irrevocable, sublicensable (through multiple levels) rights (including but not limited to reproduction rights, translation rights, compilation rights, information network dissemination rights, adaptation rights and the rights to produce derivatives, performances and displays, etc.). The scope of use of the above rights includes but is not limited to current or other websites. You hereby confirm and agree that we have the right to use or otherwise develop content in any publicity, promotion, advertising, marketing and/or research related to the above content, related websites and services (all or part). For the avoidance of doubt, you understand and agree that the rights granted above include the use, reproduction and display of personal images, portraits, names, trademarks, service marks, brands, names, logos and company marks that you own or are permitted to use embedded content. <br/>
    6.6 We have established channels for intellectual property complaints. If you find that other exhibitors infringe your intellectual property rights when you visit the relevant websites of Yoda and use the services of Yoda, you can contact us to deal with it.<br/>
    </p>

    <h4 className="font-semibold my-2.5">7. Consequences of the user's violation of this agreement</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
    7.1 If you violate this agreement, we have the right to independently judge and take pre-warnings, stop services, delete related content, restrict some or all of the functions of the account, terminate the provision of services, block the account and other measures as the situation may cause you to be unable to use it normally. We do not assume any responsibility for the consequences of accounts and major services. We have the right to announce the results of the processing within a reasonable range, and have the right to decide whether to resume the use of the relevant account based on the actual situation. We will keep relevant records for suspected violations of laws and regulations or suspected crimes, and have the right to report to the relevant competent authorities in accordance with the law, cooperate with the relevant competent authorities in investigations, and report to the public security organs. We reserve the right not to restore the deleted content.<br/>
    7.2 If you violate this agreement and cause a third-party complaint, lawsuit or claim, you should handle it by yourself and bear all the legal liabilities that may arise therefrom. If you violate this agreement and cause us and/or our affiliates to compensate any third party or be punished by the state agency, you should also fully compensate us and/or our affiliates for all losses suffered as a result.<br/>
    </p>

    <h4 className="font-semibold my-2.5">8. Disclaimer</h4>

    <p className="tips">
    Before you visit Yoda related websites and use Yoda services, please carefully read our "Disclaimer". We do not assume any form of legal responsibility for the matters listed in the "Disclaimer".<br/>

    The information ((including but not limited to company name, contact person, product description and description, relevant pictures, videos, etc.) of the companies and exhibits of the some of the columns of Beijing Yoda Technology Co., Ltd. (hereinafter referred to as "Yoda") website （<span className='text-gotinc'><Link to="/" >https://www.gotin.online</Link></span> ）and the website hosting the event, including mobile websites, WeChat official accounts, mini programs and mobile applications, and other website the Company operates from time to time (Related Websites) shall be provided by exhibitors themselves, and exhibitors shall bear full responsibility for their information in accordance with the law. In order to provide users with high-quality services, we will work hard to ensure the authenticity and legality of relevant website information, but we are not responsible for the accuracy, completeness, legality, authenticity, applicability, etc. of this information in any form.<br/>

    We and Yoda related websites does not bear any form of legal responsibility for any commercial activities that use or provide information on Yoda sites and any accidents, negligence, or disputes (including but not limited to defamation, contract disputes, intellectual property disputes, etc.), and the resulting loss (including computer virus infection due to downloading). If any of your actions cause any damage or loss to us, we reserve the right to hold you accountable for all legal liabilities.<br/>

    For any loss caused by or related to the following reasons, we are exempt from liability within the scope permitted by laws and regulations:<br/>
    </p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      (1) Unforeseen, insurmountable and unavoidable force majeure factors due to government actions, judicial administrative orders, natural disasters, wars, strikes, riots, etc.;<br/>
      (2) Due to public service factors such as power supply failures, communication network failures, or third-party factors;<br/>
      (3) If we have managed in good faith, due to factors such as routine or emergency equipment and system maintenance, equipment and system failures, network information and data security.<br/>
    </p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
    Under no circumstances will we be liable for any indirect, consequential, punitive, incidental, or special damages.<br/>

    Some related websites may contain hyperlinks to third-party websites and applications, software or services provided by third parties. You should carefully read the terms of use and privacy policies of these third-party websites, applications, software or services. We are not responsible for the authenticity, legality or security of third-party websites, applications, software or services (including data leakage, etc.). If any dispute, loss or damage arises due to such third-party websites, applications, software or services, you should resolve it with the third party yourself.<br/>

    This statement and its right to modify, update and final interpretation belong to Yoda.<br/>

    If the English version of this statement is inconsistent with the Chinese version, the Chinese version shall prevail.<br/>
    </p>

    <h4 className="font-semibold my-2.5">9. Application of law and dispute resolution</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">This agreement shall be governed by the laws of the People's Republic of China (for the purpose of this agreement, excluding Hong Kong, Macau and Taiwan) laws and shall be interpreted in accordance with them. You agree to discuss any disputes, claims, or causes between us arising from or related to this agreement or your use of Yoda related websites and services, including those related to the existence or validity of this agreement. Any disputes shall be settled by both parties through negotiation. If the negotiation fails, it shall be submitted to the competent people’s court in the place of our residence.</p>

    <h4 className="font-semibold my-2.5">10. Other</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
    10.1 The headings in this agreement are set up for the convenience of reading only, and do not affect the meaning or interpretation of any provisions in this agreement.<br/>
    10.2 Both You and Us are independent subjects. In any case, this agreement does not constitute any form of express or implied guarantee or condition for you, nor does it constitute an agency, partnership, joint venture or employment relationship between the two parties.<br/>
    10.3 If any clause of this agreement is found to be invalid or unenforceable, the invalidity or unenforceability of this clause does not affect the validity of other clauses, and the remaining clauses shall remain valid and enforced.<br/>
    </p>

  </div>
}
export default terms